<template>
  <!--begin::Page Layout-->
  <div class="d-flex flex-column-fluid">
    <!--begin::Container-->
    <div class="container">
      <div class="row align-items-stretch">
        <!--begin::infocol-->
        <div class="col-xl-8">
          <!--begin::Card-->
          <div class="card card-custom h-100">
            <div v-if="templateDataReady" class="card-body">
              <div class="d-flex">
                <!--begin::Template image-->
                <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                  <div class="symbol symbol-50 symbol-lg-200">
                    <!--begin::Profilepicture-->
                    <UploadImage
                      :container-height="200"
                      :container-width="200"
                      db-collection="templates"
                      :doc-id="templateId"
                      db-path="image"
                      :default-image="templateImageUrl"
                      cloud-function="backendTemplatesUpdateImage"
                    ></UploadImage>
                    <!--end::Profilepicture-->
                  </div>
                </div>
                <!--end::Template image-->
                <!--begin::Info-->
                <div class="flex-grow-1">
                  <!--begin::Title-->
                  <div class="d-flex align-items-center justify-content-between flex-wrap">
                    <div class="mr-3">
                      <!--begin::Template-name-->
                      <div class="
                    d-flex
                    align-items-center
                    text-dark
                    text-hover-primary
                    font-size-h5
                    font-weight-bold
                    mr-3">
                        {{ templateName }}
                      </div>
                      <!--end::Template-name-->
                      <!--begin::Details-->
                      <div class="d-flex flex-wrap my-2">
                        <p class="text-muted text-hover-primary mr-lg-8 mr-5 mb-lg-0 mb-2">
                          <i class="fad fa-user"></i>
                          <span class="mr-1 ml-2">
                        {{ templateUser.displayName }}
                      </span>
                        </p>
                        <p class="text-muted text-hover-primary mr-lg-8 mr-5 mb-lg-0 mb-2">
                          <i class="fad fa-lock"></i>
                          <span class="mr-1 ml-2">
                        {{ templateCustomer.name }}
                      </span>
                        </p>
                      </div>
                      <!--end::Details-->
                    </div>
                    <!--begin::status-pills-->
                    <div class="my-lg-0 my-1">
                      <div
                        class="btn btn-sm font-weight-bolder text-uppercase mr-3"
                        :class="[!templateStatus ? 'btn-light-success' : 'btn-light-danger']"
                      >
                        <span v-if="!templateStatus">
                          {{ capitalize(transChoice('global.active', 0)) }}
                        </span>
                        <span v-else>
                          {{ capitalize(transChoice('global.deleted', 1)) }}
                        </span>
                      </div>
                    </div>
                    <!--end::status-pills-->
                  </div>
                  <!--end::Title-->
                  <!--begin::Description-->
                  <div class="d-flex align-items-center flex-wrap justify-content-between">
                    <div class="flex-grow-1 font-weight-bold text-dark-50 py-5 py-lg-2">
                      {{ templateDesc }}
                    </div>
                  </div>
                  <!--end::Description-->
                </div>
                <!--end::Info-->
              </div>
            </div>
            <div v-else class="card-body">
              <div class="d-flex h-100 align-items-center">
                <Loader
                  :text="trans('templates.edit.loading')"
                  loader-class="relative"
                ></Loader>
              </div>
            </div>
          </div>
          <!--end::Card-->
        </div>
        <!--end::infocol-->
        <!--begin::countcol-->
        <div class="col-xl-4">
          <div v-if="templateDataReady" class="card card-custom h-100">
            <!--begin::Header-->
            <div class="card-header border-0 pt-5">
              <div class="card-title font-weight-bolder mt-0">
                <div class="card-label font-size-h5">
                  {{ trans('templates.edit.info.countcard.title') }}
                </div>
              </div>
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body">
              <div class="d-flex align-items-center flex-column justify-content-center">
                <p class="font-size-h1 font-weight-bold text-primary mb-0">
                  {{ templateCountTotal }}
                </p>
                <p class="text-dark-50">
                  {{ trans('templates.edit.info.countcard.totalCount') }}
                </p>
              </div>
            </div>
            <!--end::Body-->
          </div>
          <div v-else class="card-header border-0 pt-5">
            <div class="card-body">
              <Loader
                :text="trans('templates.edit.loading')"
                loader-class="relative"
              ></Loader>
            </div>
          </div>
        </div>
        <!--end::countcol-->
      </div>
    </div>
    <!--end::Container-->
  </div>
  <!--end::Page Layout-->
</template>

<script>
  import { SETTINGS } from '@src/store/settings'
  import { onBeforeMount, onMounted, ref, computed } from '@vue/composition-api'
  import { trans, transChoice } from '@src/core/helpers/translate'
  import { capitalize } from '@src/core/helpers/textUtils'
  import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
  import * as objectPath from 'object-path'

  export default {
    name: 'TemplateEdit',
    components: {
      Loader: () => import('@src/components/content/Loader'),
      UploadImage: () => import('@src/components/forms/UploadImage')
    },
    setup (props, { root }) {
      const fb = require('@src/firebaseConfig') // eslint-disable-line global-require
      const templateDataReady = ref(false)
      const templateId = ref(root.$route.params.templateId)
      const subMenuData = computed(() => root.$store.getters[SETTINGS.GETTERS.GET_SUBMENU_DATA])
      const subMenuName = computed(() => root.$store.getters[SETTINGS.GETTERS.GET_ACTIVE_SUBMENU])

      const templateName = ref('')
      const templateStatus = ref(false) // deleted?
      const templateVarycode = ref('')
      const templateImage = ref('')
      const templateDesc = ref('')
      const templateCountTotal = ref(0)
      const templateCustomer = ref({})
      const templateUser = ref({})

      const newSubMenuData = JSON.parse(JSON.stringify(subMenuData.value))
      objectPath.set(newSubMenuData, 'template.id', templateId.value)

      onBeforeMount(() => {
        if (subMenuName.value !== 'SubHeaderTemplatesEdit') {
          objectPath.del(newSubMenuData, 'template.name') // Reset the templatemenu
        }
        root.$store.dispatch(SETTINGS.ACTIONS.SET_ACTIVE_SUBMENU, 'TemplatesEdit')
        root.$store.dispatch(SETTINGS.ACTIONS.SET_SUBMENU_DATA, newSubMenuData)
      })

      const breadCrumbTitle = computed(() => {
        if (templateName.value === '') return capitalize(transChoice('global.loading', 2))
        return templateName.value
      })

      const setBreadCrumbs = () => {
        if (
          !objectPath.get(subMenuData, 'value.template.name', false) ||
          (templateName.value !== '' && objectPath.get(subMenuData, 'value.template.name', '') !== templateName.value) // eslint-disable-line no-extra-parens, max-len
        ) {
          newSubMenuData.template.name = templateName.value
          root.$store.dispatch(SETTINGS.ACTIONS.SET_SUBMENU_DATA, newSubMenuData)
          root.$store.dispatch(SET_BREADCRUMB, [
            {
              title: trans('nav.aside.templates.title'),
              route: '/templates'
            },
            {
              title: trans('nav.aside.templates.all'),
              route: '/templates'
            },
            {
              title: capitalize(transChoice('global.edit', 1))
            },
            {
              title: breadCrumbTitle.value
            }
          ])
        }
      }

      onMounted(() => {
        setBreadCrumbs()
      })

      const initTemplateData = async () => {
        const getTemplate = fb.functions.httpsCallable('backendTemplatesGetTemplateById')

        return await getTemplate({
          id: templateId.value
        }).then((res) => {
          // Templatepreview image
          let templateImageUrl = null
          const templateImageUrls = objectPath.get(res, 'data.result.template.image', {})
          const currentImageKey = !isNaN(parseInt(Object.keys(templateImageUrls).pop(), 10)) ? parseInt(Object.keys(templateImageUrls).pop(), 10) : false // eslint-disable-line max-len
          if (currentImageKey !== false) {
            const imgPath = objectPath.get(res, `data.result.template.image.${ currentImageKey }.path`, null)
            const imgFile = objectPath.get(res, `data.result.template.image.${ currentImageKey }.file`, null)

            if (imgPath && imgFile) {
              templateImageUrl = `${process.env.VUE_APP_IMAGE_CDN_URL}/${ imgPath }/${ imgFile }?fit=crop&w=200&h=200`
            }
          }

          const user = {
            displayName: objectPath.get(res, 'data.result.createdBy.displayName', trans('templates.edit.info.missingName')) // eslint-disable-line max-len
          }

          const customer = {
            name: objectPath.get(res, 'data.result.customer.name', trans('templates.edit.info.missingName')) // eslint-disable-line max-len
          }

          if (customer.name === 'All') customer.name = capitalize(transChoice('global.all', 1))

          templateName.value = objectPath.get(res, 'data.result.template.name', trans('templates.edit.info.missingName')) // eslint-disable-line max-len
          templateStatus.value = objectPath.get(res, 'data.result.template.deleted', false)
          templateVarycode.value = objectPath.get(res, 'data.result.template.varycode', trans('templates.edit.info.missingVarycode')) // eslint-disable-line max-len
          templateImage.value = templateImageUrl
          templateDesc.value = objectPath.get(res, 'data.result.template.desc', trans('templates.edit.info.missingDesc')) // eslint-disable-line max-len
          templateCountTotal.value = objectPath.get(res, 'data.result.template.count.total', 0) // eslint-disable-line max-len
          templateCustomer.value = customer
          templateUser.value = user

          templateDataReady.value = true
          setBreadCrumbs()
        }).catch((err) => {
          console.log('An error occurred while fetching the template:', err)
        })
      }

      initTemplateData()

      const templateImageUrl = computed(() => {
        if (templateImage.value) return templateImage.value
        return '/media/img/missing.png'
      })

      return {
        trans,
        transChoice,
        capitalize,
        templateId,
        templateName,
        templateStatus,
        templateVarycode,
        templateImage,
        templateDesc,
        templateCountTotal,
        templateCustomer,
        templateUser,
        templateImageUrl,
        templateDataReady
      }
    }
  }
</script>

<style lang="scss">

</style>
